<template>
  <div class="login-wrapper">
    <form
      class="form-login"
      v-on:submit.prevent="login()"
      action=""
      method="post">
      <h2>Cyruspay</h2>

      <v-app>
        <v-row>
          <v-col class="format-input" cols="0" sm="12" md="12">
            <v-text-field
              v-model="userName"
              :placeholder="!autofilled ? ' ' : ''"
              label="Email"
              type="email"
              autocomplete="username"
              name="userName"
              required
              outlined
            ></v-text-field>
          </v-col>
          <v-col class="format-input" cols="0" sm="12" md="12">
            <v-text-field
              v-model="passWord"
              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
              :type="show2 ? 'text' : 'password'"
              name="password"
              label="Password"
              autocomplete="current-password"
              class="input-group--focused"
              :placeholder="!autofilled ? ' ' : ''"
              @click:append="show2 = !show2"
              oninvalid="this.setCustomValidity('Password must be 8 characters and contain 1x Uppercase letter 1x lowercase letter 1x symbol 1x Number')"
              onchange="try{setCustomValidity('')}catch(e){}"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              title="Password must be 8 characters and contain 1x Uppercase letter 1x lowercase letter 1x symbol 1x Number"
              required
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </v-app>
      <div class="login">
        <button class="submit-btn btn btn-primary" type="submit">
          <span class="span">Login</span>
          <img class="imgbtnLogin" src="/assets/img/icon_login.png" />
        </button>
      </div>
      <div class="line" />
      <div
        class="forgot-pw os-dropdown-trigger"
        @click="gotoForgotPassWord()"
        style="margin-top: 10px"
      >
        Forgot Password?
      </div>
      <div class="divnbtn">
        <div class="txtAccount">Don't have an account yet?</div>
        <span class="forgot-pw os-dropdown-trigger" @click="gotoRegister()"
          >Sign up</span
        >
      </div>
    </form>
  </div>
</template>

<script>
// import firebase from 'firebase/app';
import "firebase/auth";
import {
  CHECK_CODE_ENCODE,
  LOGIN,
  ADD_ACCOUNTANT,
  // LOGIN_WITH_GOOGLE,
  SAVE_DEVICE,
  USER_INFO,
} from "@/store/actionsType";
import * as Session from "@/utils/SessionStore";
import * as Encode from "@/utils/Encodepassword";
import ApiManager from "@/api/ApiManager";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      show2: false,
      userName: "",
      passWord: "",
      isInvitationAccountant: false,
      code: "",
      companyId: "",
      autofilled: false,
    };
  },
  watch: {
    userName() {
      this.autofilled = true;
    },
  },
  created: function () {
    if (this.$route.query.code != undefined) {
      this.code = this.$route.query.code;
      this.companyId = this.$route.query.companyid;
      this.checkCodeEncode();
    }
  },
  methods: {
    checkCodeEncode() {
      this.$root.$refs.AppCyrus.start();
      this.$store
        .dispatch(CHECK_CODE_ENCODE, this.code)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          if (!response.result) {
            this.$router.push({
              path: "/link-expired",
            });
          } else {
            this.isInvitationAccountant = true;
          }
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.finish();
        });
    },

    async login() {
      this.$root.$refs.AppCyrus.start();
      // Model request
      var passWordEncode = await Encode.encryptPass(this.passWord);
      if (passWordEncode != null) {
        // console.log(passWordEncode);
        var token2FA = "";
        if (Session.has("2FAToken")) {
          token2FA = Session.get("2FAToken");
        }
        var dataRequest = {
          userName: this.userName,
          passwordHash: passWordEncode,
          code2FA: "",
          remembere2FAToken: token2FA,
          remembere2FA: false,
        };
        Session.set("isLogin", true);
        
        this.$store
          .dispatch(LOGIN, dataRequest)
          .then((response) => {
            Session.set("isLogin", false);
            if (response != undefined) {
              if (response == "User require login with 2fa.") {
                this.$root.$refs.AppCyrus.finish();
                this.$router.push({
                  name: "twofactorauthen",
                  params: {
                    userName: this.userName,
                    password: passWordEncode,
                    isInvitationAccountant: this.isInvitationAccountant,
                    code: this.code,
                    companyId: this.companyId,
                  },
                });
              } else {
                if (this.isInvitationAccountant) {
                  var dataRequest = {
                    codeEncode: this.code,
                    companyId: this.companyId,
                    email: this.userName,
                  };
                  this.$store
                    .dispatch(ADD_ACCOUNTANT, dataRequest)
                    .then(() => {
                      this.successHandling(response);
                    })
                    .catch(() => {
                      this.$root.$refs.AppCyrus.fail();
                      this.errorHandling();
                    });
                } else {
                  this.successHandling(response);
                }
              }
            }
          })
          .catch(() => {
            this.$root.$refs.AppCyrus.fail();
            this.errorHandling();
          });
      } else {
        this.$root.$refs.AppCyrus.fail();
      }
    },

    successHandling(accuracy) {
      this.$store
        .dispatch(USER_INFO)
        .then((response) => {
          this.$root.$refs.AppCyrus.finish();
          Session.remove("currenCompanyId");
          Session.remove("currenCompanyName");
          var fName = response.firstName == null ? "" : response.firstName;
          var name = fName + " " + response.lastName;
          Session.set("Username", name);
          Session.set("2FAToken", accuracy.remembere2FAToken);
          var date = new Date();
          date.setHours(0);
          date.setMinutes(0);
          date.setSeconds(0);
          date.setMilliseconds(0);
          Session.set("dateLogin", date);
          if (response.employeeId > 0) {
            Session.set("currentEmployeeId", response.employeeId);
          }
          Session.set("Profile", {
            id: response.id,
            firstName: "",
            lastName: response.lastName,
            birthDate: date,
            email: this.userName,
            role: response.role,
            creationTime: response.creationTime,
            userSubscription: response.userSubscription,
            currentEmpCount: response.currentEmpCount,
            currentCompanyCount: response.currentCompanyCount,
            allowedEmployeeInPLan: response.allowedEmployeeInPLan,
          });
          this.$store.dispatch(SAVE_DEVICE, response.id);
          if (accuracy.subscriptionStatus == "SUBSCRIPTIONEXPIRED") {
            Session.set("SubscriptionExpiredMode",true)
            this.$root.$refs.AppCyrus.finish();
            this.$router.push({
              path: "/SubscriptionPayment",
            });
          } else {
             Session.set("SubscriptionExpiredMode",false)
            if (accuracy.role != "Employee") {
              if (this.isInvitationAccountant || accuracy.isSetupCompany) {
                this.$router.push({
                  path: "/dashboard",
                });
              } else {
                this.$router.push({
                  name: "company",
                  params: {
                    isNotBack: false,
                  },
                });
              }
            } else {
              this.$router.push({
                path: "/Leave",
              });
            }
          }
        })
        .catch(() => {
          this.$root.$refs.AppCyrus.fail();
          this.errorHandling();
        });
    },
    errorHandling() {
      Session.set("isLogin", false);
      if (Session.get("hasGetHandshake")) {
        ApiManager.getHandShake()
          .then(() => {
            Session.set("hasGetHandshake", false);
            this.login();
          })
          .catch(() => {
            if (this.$global.path != "/updateserver") {
              this.$modal.show("modal-tryhandshake", {
                data: "",
              });
            }
          });
      }
    },
    loginWithGoogle() {
      var date = new Date();
      var characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 10; i++) {
        var string =
          string +
          characters.charAt(Math.floor(Math.random() * characters.length));
      }
      if (this.isInvitationAccountant) {
        Session.set("dataAddAccountant", {
          companyId: this.companyId,
          code: this.code,
        });
      }
      var state = "Security" + date.getTime() + string;
      window.location.href =
        "https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=391983556908-9pr4072jg6qj8ktres7nq2bbfrk33p90.apps.googleusercontent.com&scope=openid%20email%20profile&redirect_uri=" +
        ApiManager.getUrlDomainUI() +
        "/accuracy-gmail&state=" +
        state +
        "&hd=gmail.com";
      Session.set("state", state);
    },

    gotoRegister() {
      var query = "";
      if (this.isInvitationAccountant) {
        query = "?companyid=" + this.companyId + "&code=" + this.code;
      }
      this.$router.push({
        path: "/register" + query,
        param: {},
      });
    },
    gotoForgotPassWord() {
      this.$router.push({
        path: "/forgotpass",
        param: {},
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
@import "/assets/css/login.css";
</style>
